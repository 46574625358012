exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-czym-jest-aurec-tsx": () => import("./../../../src/pages/czym-jest-aurec.tsx" /* webpackChunkName: "component---src-pages-czym-jest-aurec-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jak-korzystac-tsx": () => import("./../../../src/pages/jak-korzystac.tsx" /* webpackChunkName: "component---src-pages-jak-korzystac-tsx" */),
  "component---src-pages-kim-jestesmy-tsx": () => import("./../../../src/pages/kim-jestesmy.tsx" /* webpackChunkName: "component---src-pages-kim-jestesmy-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-polityka-cookies-tsx": () => import("./../../../src/pages/polityka-cookies.tsx" /* webpackChunkName: "component---src-pages-polityka-cookies-tsx" */),
  "component---src-pages-polityka-prywatnosci-tsx": () => import("./../../../src/pages/polityka-prywatnosci.tsx" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */)
}

