import type { Breakpoints, Colors, Fonts } from 'types/theme'

export const breakpoints: Breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1920,
}

export const colors: Colors = {
  red: '#DD001A',
  orange: '#FF9D00',
  green: '#449730',
  blue: '#0065B2',
  lightred: '#fce5e7',
  lightorange: '#fff5e5',
  lightgreen: '#f4f9f3',
  lightblue: '#E5EFF7',
  white: '#FFFFFF',
  dark: '#313133',
  black: '#000000',
}

export const fonts: Fonts = {
  size: '16px',
}

const theme = {
  breakpoints,
  colors,
  fonts,
}

export default theme
